var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.currentComponent, {
    tag: "div",
    staticClass: "fo-box"
  }, [_c("Preview")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };