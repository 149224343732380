import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "preview_container"
  }, [_c("div", {
    staticClass: "login_left"
  }, [_c("img", {
    staticClass: "img_icon",
    attrs: {
      src: _vm.ss_icon,
      alt: ""
    }
  }), _c("img", {
    staticClass: "img_text",
    attrs: {
      src: _vm.ss_text,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "login_right"
  }, [_c("div", {
    staticClass: "new_login"
  }, [_c("p", {
    staticClass: "top_title",
    class: _vm.lang
  }, [_vm._v(_vm._s(_vm.$t("common.appName")))]), _c("div", {
    staticClass: "ipt_boxs"
  }, [_c("div", {
    staticClass: "first_box com_box"
  }, [_c("a-input", {
    staticClass: "ipt_com",
    attrs: {
      placeholder: _vm.$t("login.username")
    },
    on: {
      pressEnter: _vm.pressEnterLogin,
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "username"
    }
  })], 1), _c("div", {
    staticClass: "second_box com_box"
  }, [_c("a-input", {
    staticClass: "ipt_com",
    attrs: {
      autoComplete: "off",
      type: _vm.pawdtype,
      placeholder: _vm.$t("login.password")
    },
    on: {
      pressEnter: _vm.pressEnterLogin,
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "password"
    }
  }), _c("span", {
    on: {
      click: function click($event) {
        return _vm.isShow();
      }
    }
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: _vm.pwdImg,
      alt: ""
    }
  })])], 1), _c("div", {
    staticClass: "second_box com_box"
  }, [_c("a-checkbox", {
    model: {
      value: _vm.remember,
      callback: function callback($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  }, [_vm._v(_vm._s(_vm.$t("login.remember")))])], 1)]), _c("div", {
    staticClass: "not_login_btn",
    class: _vm.isLogin ? "login_btn" : "",
    on: {
      click: function click($event) {
        return _vm.handleLogin();
      }
    }
  }, [_vm._v(" Log In" + _vm._s(_vm.loading ? "..." : "") + " ")])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };