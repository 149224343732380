import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import { mapState, mapMutations, mapGetters } from "vuex";
import { Api } from "@/api";
export default {
  //组件名字
  name: "preview",
  props: {},
  components: {},
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters('lang', ['lang'])), mapState({
    userInfo: function userInfo(state) {
      return state.base.userInfo;
    }
  })), {}, {
    isLogin: function isLogin() {
      return this.username.length && this.password.length;
    },
    isDev: function isDev() {
      return process.env.NODE_ENV === "development";
    },
    pwdImg: function pwdImg() {
      return this.pawdtype === "password" ? this.notSeeImg : this.seeImg;
    }
  }),
  // vue数据集中管理
  data: function data() {
    return {
      username_msg: "",
      password_msg: "",
      username: "admin",
      //admin
      password: "Abc123",
      //123abc
      seeImg: require("../../assets/img/passwd/see.png"),
      notSeeImg: require("../../assets/img/passwd/not_see.png"),
      pawdtype: "password",
      ss_icon: require("../../assets/img/ss_icon.png"),
      ss_text: require("../../assets/img/ss_text.png"),
      remember: false,
      loading: false
    };
  },
  //函数写这里
  methods: _objectSpread(_objectSpread({}, mapMutations("base", ["setUserInfo"])), {}, {
    valTrim: function valTrim(e) {
      e.target.value = e.target.value.trim();
      return e;
    },
    isShow: function isShow() {
      this.pawdtype = this.pawdtype === "password" ? "text" : "password";
    },
    pressEnterLogin: function pressEnterLogin() {
      if (!this.username.length) {
        return false;
      }
      if (!this.password.length) {
        return false;
      }
      this.handleLogin();
    },
    handleLogin: function handleLogin() {
      var _this = this;
      if (!this.username) {
        this.username_msg = "请输入正确账户名";
        return;
      }
      if (!this.password) {
        this.password_msg = "请输入正确密码";
        return;
      }
      var params = {
        username: this.username,
        password: this.password,
        remember: this.remember
      };
      if (this.loading) return false;
      this.loading = true;
      Api.login(params).then(function (res) {
        if (res && Object.keys(res).length) {
          var userInfo = JSON.parse(JSON.stringify(res));
          localStorage.setItem(
          // 海外项目运营后台用户信息标识
          "overseasOperationsUser", JSON.stringify(_objectSpread({}, userInfo)));
          _this.setUserInfo(_objectSpread({}, userInfo));
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
          _this.$router.push({
            path: "/welcome/index"
          });
        }
      }).catch(function (err) {
        _this.loading = false;
        // console.log('err', err);
      });
    }
  }),

  watch: {},
  created: function created() {
    this.username = this.isDev ? "admin" : ""; //admin
    this.password = this.isDev ? "Abc123" : ""; //Abc123
    // this.username = "", //admin
    // this.password = "", //Abc123
    var _ = this;
    if (_.userInfo && _.userInfo.token) {
      var path = "/welcome/index";
      _.$router.push(path);
      return;
    }
  },
  //过滤器
  filters: {},
  //页面渲染之后
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {}
};