import Preview from "./preview";
export default {
  name: 'login',
  data: function data() {
    return {
      currentComponent: Preview
    };
  },
  components: {
    Preview: Preview
  },
  mounted: function mounted() {
    this.currentComponent = Preview;
  }
};